<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import DropDown from '../individual-entry-component/drop-down.vue';
  import { PageNodeModel } from '../../page-management-tool/PageNode.js';
  import { rawResponse, usePageNodeStore } from '../../page-management-tool/page-node-store.js';
  import { FilterDynamically, GetValueDynamically } from '../../composables.js';
  import { SearchFilterDropDown } from '../../page-management-tool/tool-config/filter-config.js';
  import type { AdditionalUrlsData, CustomRequest } from '../../page-management-tool/pmt-interfaces.js';
  const props = defineProps<{
    pageNodeId: string,
    langSelected: string,
    pageUrl: string,
    redirects: AdditionalUrlsData[],
  }>();
  const emits = defineEmits<{
    (e: 'closeModal'): void
  }>();
  const searchInput = ref<string>('');
  const searchFilter = ref<string>('URL');
  const activeIdx = ref<number>(0);
  const PageToRedirect = ref<PageNodeModel>();
  const readyForRedirect = ref<boolean>(false);
  const pathToField = ref<string>(SearchFilterDropDown.find(x => x.displayField == searchFilter.value)!.actualField);
  const PageNodeStore = usePageNodeStore();
  const allPages = rawResponse.filter(page => page.getUrl != props.pageUrl && page.getAvailableLanguages.includes(props.langSelected));
  function emitEvent() {
    emits('closeModal');
  }
  function PageSelected(page: PageNodeModel) {
    PageToRedirect.value = page;
    readyForRedirect.value = true;
    searchInput.value = GetValueDynamically(page, pathToField.value);
  }
  function ResetSearch() { 
    readyForRedirect.value = false;
    searchInput.value = '';
  }
  const filteredList = computed(() => {
    pathToField.value = SearchFilterDropDown.find(x => x.displayField == searchFilter.value)!.actualField;
    return FilterDynamically<PageNodeModel>(allPages, searchInput.value, pathToField.value);
  });
  watch(searchInput, (newValue, oldValue) => {
    if (oldValue.length > newValue.length){
      readyForRedirect.value = false; //on change on input
    }
  });
  function DeleteAndRedirectPage() {
    const data: CustomRequest = {
      PageNodeId: PageToRedirect.value!.getNodeId,
      Field: 'Urls', //Field to be update after deletion
      Value: props.pageNodeId, //NODE TO DELETE
      TypeOfRequest: 'DeleteAndRedirect',
      LanguageCode: props.langSelected,
    };
    PageNodeStore.DeleteAndRedirect(data).then(()=> emitEvent());
  }
</script>

<template>
  <div id="js-modal" class="edit-modal">
    <div class="modal-content">
      <div class="delete-redirect">
        <div class="modal-heading">
          <p><strong>Delete Page and Redirect</strong></p>
        </div>
        <div class="url-container">
          <div class="url-label">
            Page to be <strong>DELETED</strong>:
          </div>
          <div class="url-value">
            {{ pageUrl }}
          </div>
          <div class="url-label">
            Lang:
          </div>
          <div class="url-value">
            {{ langSelected }}
          </div>
        </div>
        <div class="search-label">
          Select a Page to Redirect to:
        </div>
        <div class="search-wrapper flex">
          <DropDown :drop-down-values="SearchFilterDropDown.slice(0, 2).map(x => x.displayField)"
            :first-value-defualt="true"
            @drop-down-change="(data) => searchFilter = data"
          />
          <input class="search" v-model="searchInput" type="text" placeholder="Search a page...">
          <svg class="delete-svg"
            width="18"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            @click="ResetSearch()"
          >
            <path 
              fill="#4e4e50"
              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
            />
          </svg>
        </div>
        <div class="page-list" v-if="!readyForRedirect && searchInput">
          <div v-for="(page, idx) in filteredList" 
            :key="page.getNodeId"
            :class="{ hover: idx === activeIdx }"
            class="page-entry"
            @mouseover="activeIdx = idx;"
            @click="PageSelected(page)"
          >
            {{ GetValueDynamically(page, pathToField) }}
            <span class="pull-right">{{ langSelected }}</span>
          </div>
        </div>
        <div class="selected-page">
          Page Selected to Redirect Traffic:
          <div class="selected-info" v-show="readyForRedirect">
            <div>
              <strong>TITLE: </strong>
              <div class="selected-Title">
                {{ PageToRedirect?.LangSpecificPageData.Name }}
              </div>
            </div>
            <div>
              <strong>URL: </strong>
              <div class="selected-Url">
                {{ PageToRedirect?.getUrl }}
              </div>
            </div>
            <div>
              <strong>LANG: </strong>
              <div class="selected-Language">
                {{ langSelected }}
              </div>
            </div>
          </div>
        </div>
        <button id="js-modal-close" type="button" class="close-modal-button" @click="emitEvent()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.0455 8L15.5909 12.5455C15.8636 12.8182 16 13.1818 16 13.5455C16 13.9545 15.8636 14.3182 15.5909 14.5909L14.5909 15.5909C14.2727 15.8636 13.9091 16 13.5455 16C13.1364 16 12.8182 15.8636 12.5455 15.5909L8 11.0455L3.45455 15.5909C3.18182 15.8636 2.81818 16 2.45455 16C2.04545 16 1.68182 15.8636 1.40909 15.5909L0.409091 14.5909C0.136364 14.3182 0 13.9545 0 13.5455C0 13.1818 0.136364 12.8182 0.409091 12.5455L4.95455 8L0.409091 3.45455C0.136364 3.18182 0 2.86364 0 2.45455C0 2.09091 0.136364 1.72727 0.409091 1.40909L1.40909 0.409091C1.68182 0.136364 2.04545 0 2.45455 0C2.81818 0 3.18182 0.136364 3.45455 0.409091L8 4.95455L12.5455 0.409091C12.8182 0.136364 13.1364 0 13.5455 0C13.9091 0 14.2727 0.136364 14.5909 0.409091L15.5909 1.40909C15.8636 1.72727 16 2.09091 16 2.45455C16 2.86364 15.8636 3.18182 15.5909 3.45455L11.0455 8Z"
              fill="#4E4E50"
            />
          </svg>
        </button>
        <div class="button-container">
          <button type="button" class="btn btn-teal" style="margin-right: 5px;" @click="emitEvent()">
            Cancel
          </button>
          <button type="button" class="btn btn-teal" @click="DeleteAndRedirectPage()" v-if="readyForRedirect">
            Delete Page and Redirect
          </button>
        </div>
      </div>
    </div>
  </div>
</template>